<template>
  <div class="main-content">
    <el-row>
      <el-col :span="24">
        <content-header :title-list="titleList"></content-header>
      </el-col>
    </el-row>
    <Detail v-if="show" :schoolId="schoolId" :orgId="orgId" />
  </div>
</template>

<script>
import Detail from "./components/Detail";
import ContentHeader from "@/components/Common/ContentHeader";

export default {
  components: { Detail, ContentHeader },
  data() {
    return {
      titleList: [
        {
          text: "产品服务",
          isCur: false,
          link: ""
        },
        {
          text: "新增",
          isCur: true,
          link: ""
        }
      ],
      show: false,
      orgId: ""
    };
  },
  created() {
    this.schoolId = this.$route.query.schoolId;
    this.orgId = this.$route.query.orgId;
    this.show = true;
  }
};
</script>
